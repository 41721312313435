import { forwardRef, InputHTMLAttributes } from 'react';
import { IconName, Icon } from '@app/components/icon';
import { InputElement, InputIconWrapper, InputWrapper } from './input.styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  icon?: IconName;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ isError, icon, ...rest }, ref) => (
  <InputWrapper>
    <InputElement ref={ref} $isError={isError} $withIcon={!!icon} {...rest} />
    {icon && (
      <InputIconWrapper>
        <Icon name={icon} size={12} color={isError ? 'danger' : 'grayDark'} />
      </InputIconWrapper>
    )}
  </InputWrapper>
));
