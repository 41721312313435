import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const CapturesViewWrapper = styled.div<{ $thumbnailWidth?: number }>`
  --thumb-width: 100%;

  ${breakpointsMedia.sm} {
    --thumb-width: ${({ $thumbnailWidth = 385 }) => $thumbnailWidth}px;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, var(--thumb-width));
  gap: 20px;
  justify-content: center;
  align-items: start;
`;
