import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const CapturesItemButtons = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export const CapturesItemThumbnail = styled.img`
  width: 100%;
  cursor: pointer;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  min-height: 160px;
`;

export const CapturesItemWrapper = styled.div`
  position: relative;
  ${breakpointsMedia.lg} {
    & ${CapturesItemButtons} {
      opacity: 0;
      transition:
        transform 0.2s ease-in-out,
        opacity 0.2s ease-in-out;
    }
    &:hover ${CapturesItemButtons} {
      opacity: 1;
      transform: translateY(0);
    }

    &:hover ${CapturesItemThumbnail} {
      transform: scale(1.03);
    }
  }
`;

export const CapturesItemFilename = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 80px;
`;
