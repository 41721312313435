import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { CapturesFiltersInput } from '@app/__generated__/graphql';
import { FC, ReactNode, useCallback } from 'react';

const generateCapturesZipUrlDocument = graphql(`
  mutation generateCapturesZipUrl($filename: String!, $filters: CapturesFiltersInput!) {
    url: generateCapturesZipUrl(filename: $filename, filters: $filters)
  }
`);

const capturesSizeDocument = graphql(`
  query capturesSize($filters: CapturesFiltersInput!) {
    size: capturesSize(filters: $filters)
  }
`);

interface DownloadCapturesZipProps {
  children: (data: { download: () => void; size: number; loading: boolean }) => ReactNode;
  filename: string;
  filters: CapturesFiltersInput;
}

export const DownloadCapturesZip: FC<DownloadCapturesZipProps> = ({ children, filename, filters }) => {
  const [generate, { loading }] = useMutation(generateCapturesZipUrlDocument, {
    variables: { filename, filters },
    onError: getErrorHandler(),
  });

  const { data } = useSuspenseQuery(capturesSizeDocument, {
    variables: { filters },
  });

  const download = useCallback(() => {
    generate({ variables: { filename, filters } }).then(({ data }) => {
      if (data) document.location.href = data.url;
    });
  }, [generate, filename, filters]);

  if (!data.size) return <></>;
  return children({ download, size: data.size, loading });
};
