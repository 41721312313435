import styled from 'styled-components';

export const NavCalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 10px 20px 40px 20px 10px;
  align-items: center;
  justify-items: center;
  gap: 5px;
`;

export const NavCalendarOption = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grayDark};
`;

interface NavCalendarCurrentProps {
  $day: string;
  $month: string;
}

export const NavCalendarCurrent = styled.div<NavCalendarCurrentProps>`
  position: relative;

  &::after {
    content: '${({ $day }) => $day}';
    position: absolute;
    left: 50%;
    top: 30%;
    font-size: 13px;
    line-height: 13px;
    font-weight: 700;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.colors.primary};
  }

  &::before {
    content: '${({ $month }) => $month}';
    position: absolute;
    left: 50%;
    bottom: 12%;
    font-size: 9px;
    line-height: 9px;
    font-weight: 700;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.colors.primary};
  }
`;

interface NavCalendarButtonProps {
  $active?: boolean;
  $rotate?: boolean;
}

export const NavCalendarButton = styled.div<NavCalendarButtonProps>`
  position: relative;

  transform: rotate(${({ $rotate }) => ($rotate ? '180' : '0')}deg);

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform-origin: center;
    transform: scale(3);
    cursor: ${({ $active }) => ($active ? 'pointer' : 'default')};
  }
`;
