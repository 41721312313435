import { FC } from 'react';
import { Flex } from '@app/layout/flex';
import { Icon, IconName } from '@app/components/icon';
import { ActionsTabLabel, ActionsTabValue, ActionsTabWrapper } from './actions-tab.styles';

interface ActionsTabProps {
  area: string;
  value: number;
  label: string;
  icon: IconName;
}

export const ActionsTab: FC<ActionsTabProps> = ({ area, value, label, icon }) => {
  return (
    <ActionsTabWrapper area={area}>
      <Flex gap={15}>
        <Icon name={icon} size={42} color="white" />
        <Flex gap={6} alignItems="center" row>
          <Icon name="tick" size={14} color="secondary" />
          <ActionsTabLabel>{label}</ActionsTabLabel>
        </Flex>
      </Flex>
      <Flex>
        <ActionsTabValue>{value}</ActionsTabValue>
      </Flex>
    </ActionsTabWrapper>
  );
};
