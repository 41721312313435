import { EmptyTopItemsItem, EmptyTopItemsWrapper } from './empty-top-items.styles';

export const EmptyTopItems = () => {
  return (
    <EmptyTopItemsWrapper>
      <EmptyTopItemsItem />
      <EmptyTopItemsItem />
      <EmptyTopItemsItem />
    </EmptyTopItemsWrapper>
  );
};
