import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParam = (name: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(name);

  const setValue = useCallback(
    (value: string | null) => {
      const currentValue = searchParams.get(name);
      if (value === currentValue) return;
      if (!value) searchParams.delete(name);
      else searchParams.set(name, value);
      setSearchParams(searchParams);
    },
    [searchParams, name, setSearchParams],
  );

  return [value, setValue] as const;
};
