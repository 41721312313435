import dayjs from 'dayjs';
import { StatsView } from '../../views/stats/stats-view';
import { FC } from 'react';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';

const statsDocument = graphql(`
  query stats($galleryId: UUID!) {
    stats(galleryId: $galleryId) {
      gallery {
        id
        endsAt
      }
      analytics {
        views {
          total
          unique
        }
        deviceViews {
          mobile
          desktop
        }
        actions {
          shareLink
          shareFile
          download
        }
        topCaptures {
          id
          shortId
          uploadFilename
          status
          file {
            path
          }
          preview {
            path
          }
          thumbnail {
            path
          }
        }
      }
      captures {
        count
        marketingCount
        hourlyCount {
          date
          count
        }
        peopleCount
      }
      external {
        printsCount
        potentialUsersCount
      }
    }
  }
`);

interface StatsProps {
  galleryId: string;
}

export const Stats: FC<StatsProps> = ({ galleryId }) => {
  const { data } = useSuspenseQuery(statsDocument, { variables: { galleryId } });

  const endsAt = data.stats.gallery.endsAt;
  const isEarly = !!endsAt && dayjs(endsAt).diff(new Date(), 'hour') > -24;

  const { stats } = data;
  const { shareLink, shareFile } = stats.analytics.actions;

  const statsViewData = {
    totalViews: stats.analytics.views.total,
    uniqueViews: stats.analytics.views.unique,
    mobileViews: stats.analytics.deviceViews.mobile,
    desktopViews: stats.analytics.deviceViews.desktop,
    shareCount: shareLink + shareFile,
    downloadsCount: stats.analytics.actions.download,
    capturesCount: stats.captures.count,
    marketingCount: stats.captures.marketingCount,
    topCaptures: stats.analytics.topCaptures,
    peopleCount: stats.captures.peopleCount,
    printsCount: stats.external.printsCount,
    potentialUsersCount: stats.external.potentialUsersCount,
    hourlyCounts: stats.captures.hourlyCount,
  };

  return <StatsView isEarly={isEarly} stats={statsViewData} />;
};
