import { groupBy } from 'lodash-es';
import { DateCount } from '../interfaces/date-count.interface';
import { StatsDate } from '../interfaces/stats-date.interface';
import { HourlyCount } from '../interfaces/hourly-count.interface';

interface HourlyCountsManagerInput {
  hourlyCounts: DateCount[];
}

export const createHourlyCountsManager = ({ hourlyCounts }: HourlyCountsManagerInput) => {
  const detailedHourlyCounts = hourlyCounts.map((hourlyCount) => ({
    count: hourlyCount.count,
    hour: new Date(hourlyCount.date).getHours(),
    month: new Date(hourlyCount.date).getMonth(),
    day: new Date(hourlyCount.date).getDate(),
  }));

  const hourlyByDayId = groupBy(detailedHourlyCounts, ({ month, day }) => `${month}-${day}`);

  const dates = Object.keys(hourlyByDayId)
    .filter((dayId) => hourlyByDayId[dayId].length > 1)
    .map((dayId) => {
      const [month, day] = dayId.split('-');
      return { day: Number(day), month: Number(month) };
    });

  const getHourlyCounts = ({ day, month }: StatsDate) => {
    const data = hourlyByDayId[`${month}-${day}`] || null;
    if (!data) throw new Error('Cannot find hourly counts');
    return data.map((d) => ({ hour: d.hour, count: d.count })) as HourlyCount[];
  };

  return { dates, getHourlyCounts };
};
