import { FC } from 'react';
import { LayoutItem } from '@app/layout/layout-item';
import { Text } from '@app/layout/text';
import { StatsTab } from '../stats-tab';
import { StatsPieCharts } from '../charts/stats-pie-chart';

interface DeviceViewsTabProps {
  mobileViews: number;
  desktopViews: number;
}

const getChartData = (mobile: number, desktop: number) => {
  if (mobile + desktop === 0) return null;
  return [
    { name: 'Mobile', value: mobile, color: '#7B08FF' },
    { name: 'Desktop', value: desktop, color: '#F6384D' },
  ];
};

export const DeviceViewsTab: FC<DeviceViewsTabProps> = ({ mobileViews, desktopViews }) => {
  const chartData = getChartData(mobileViews, desktopViews);

  return (
    <StatsTab grid>
      <Text size="xs">Views mobile / Views desktop</Text>
      <LayoutItem justify="center">
        <StatsPieCharts data={chartData} />
      </LayoutItem>
    </StatsTab>
  );
};
