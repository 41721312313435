import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const StatsViewWrapper = styled.div`
  display: grid;
  gap: 20px;
`;

export const StatsViewBase = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, max-content);

  grid-template-areas:
    'av tc'
    'pc pu'
    'p s';

  ${breakpointsMedia.md} {
    gap: 20px;
  }

  ${breakpointsMedia.lg} {
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas:
      'av av av av tc tc pc pc pu pu'
      'av av av av tc tc pc pc pu pu'
      'av av av av tc tc pc pc pu pu'
      'av av av av tc tc pc pc pu pu'
      'av av av av p p p s s s'
      'av av av av p p p s s s'
      'av av av av p p p s s s';
  }
`;

export const StatsViewCharts = styled.div`
  display: grid;
  gap: 15px;

  ${breakpointsMedia.lg} {
    gap: 20px;
    grid-template-columns: 4fr 6fr;
  }
`;

export const StatsViewWarning = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.base};
  color: ${({ theme }) => theme.colors.danger};
  margin: 40px 0px;
  text-align: center;
`;
