import { FC, useCallback, useEffect, useState } from 'react';
import { ViewCapture } from '../../views/captures/interfaces';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Zoom, Virtual, Keyboard } from 'swiper/modules';
import { CaptureModal } from './modal';
import { getSurrounding } from '@app/utils/array.util';
import { useSwiperLock } from './hooks/use-swiper-lock';
import { CaptureSlide } from './slide';
import 'swiper/css';
import 'swiper/css/zoom';

interface CaptureModalProps {
  currentIndex: number;
  onChange: (index: number | null) => void;
  captures: ViewCapture[];
}

export const CaptureSwiper: FC<CaptureModalProps> = ({ currentIndex, onChange, captures }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const lockSwiper = useSwiperLock(swiperRef);
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    swiperRef?.slideTo(currentIndex);
  }, [swiperRef, currentIndex]);

  const currentCapture = captures[currentIndex];

  const [loaded, setLoaded] = useState<ViewCapture[]>([]);
  const setCaptureLoaded = useCallback((c: ViewCapture) => setLoaded((prev) => [...prev, c]), []);

  const shouldRenderCapture = (c: ViewCapture) => currentCapture === c || loaded.includes(c) || loaded.includes(currentCapture);

  const handleTransitionEnd = (e: SwiperClass) => {
    onChange(e.activeIndex);
    const toLoad = getSurrounding(captures, e.activeIndex);
    setLoaded((prev) => prev.filter((i) => toLoad.includes(i)));
  };

  return (
    <CaptureModal
      onClose={() => onChange(null)}
      hasPrev={currentIndex > 0}
      hasNext={currentIndex < captures.length - 1}
      onPrev={() => swiperRef?.slidePrev()}
      onNext={() => swiperRef?.slideNext()}
    >
      <Swiper
        onSwiper={setSwiperRef}
        initialSlide={currentIndex}
        spaceBetween={30}
        keyboard={{ enabled: true }}
        zoom={true}
        modules={[Zoom, Virtual, Keyboard]}
        virtual
        onZoomChange={(_, scale) => {
          setIsZoomed(scale !== 1);
        }}
        onTransitionEnd={handleTransitionEnd}
        style={{ height: '100%' }}
      >
        {captures.map((capture, idx) => (
          <SwiperSlide key={idx} virtualIndex={idx}>
            <CaptureSlide
              capture={capture}
              onLoad={() => setCaptureLoaded(capture)}
              isCurrent={currentIndex === idx}
              isZoomed={isZoomed}
              onSeek={lockSwiper}
              shouldLoad={shouldRenderCapture(capture)}
              onBgClick={() => onChange(null)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </CaptureModal>
  );
};
