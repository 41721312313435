import { FC } from 'react';
import { LayoutItem } from '@app/layout/layout-item';
import { Text } from '@app/layout/text';
import { StatsTab } from '../stats-tab';
import { Flex } from '@app/layout/flex';
import { StatsPieCharts } from '../charts/stats-pie-chart';
import { Responsive } from '@app/components/responsive';

interface MarketingTabProps {
  marketingCount: number;
  capturesCount: number;
}

const getChartData = (agree: number, notAgree: number) => {
  if (agree + notAgree === 0) return null;
  return [
    { name: 'Agreed', value: agree, color: '#52C41A' },
    { name: 'Not agreed', value: notAgree, color: '#F6384D' },
  ];
};

export const MarketingTab: FC<MarketingTabProps> = ({ marketingCount, capturesCount }) => {
  const percent = Math.round((marketingCount / capturesCount) * 100);
  const chartData = getChartData(marketingCount, capturesCount - marketingCount);

  return (
    <StatsTab grid>
      <Text size="xs">Marketing consent</Text>
      <LayoutItem justify="center">
        <Flex row gap={100}>
          <Responsive target="desktop">
            <Flex alignItems="center" justifyContent="center" gap={0}>
              <Text size="3xl" weight={800} color={percent >= 50 ? 'success' : 'danger'}>
                {percent}%
              </Text>
              <Text size="xs" color="grayDark">
                agreed
              </Text>
            </Flex>
          </Responsive>
          <StatsPieCharts data={chartData} />
        </Flex>
      </LayoutItem>
    </StatsTab>
  );
};
