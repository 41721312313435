import { breakpointsMedia } from '@app/styles/breakpoints';
import styled from 'styled-components';

export const CaptureSlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${breakpointsMedia.md} {
    justify-content: center;
  }
`;

export const CaptureSlideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${breakpointsMedia.md} {
    gap: 15px;
    width: auto;
    height: auto;
  }
`;

export const CaptureSlidePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
