import { FC, useState } from 'react';
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Flex } from '@app/layout/flex';
import { Text } from '@app/layout/text';
import { NavCalendar } from './nav-calendar';
import { createHourlyCountsManager } from './utils/hourly-counts-manager.util';
import { DateCount } from './interfaces/date-count.interface';
import { getChartData } from './utils/hourly-counts-charts-data.util';
import { StatsTab } from '../stats-tab';

interface HourlyCountsTabProps {
  hourlyCounts: DateCount[];
}

export const HourlyCountsTab: FC<HourlyCountsTabProps> = ({ hourlyCounts }) => {
  const { dates, getHourlyCounts } = createHourlyCountsManager({ hourlyCounts });

  const [selectedDate, setSelectedDate] = useState<{ day: number; month: number } | null>(dates[0] || null);

  const selectedData = selectedDate && getHourlyCounts(selectedDate);

  const chartData = getChartData(selectedData);
  return (
    <StatsTab grid>
      <Flex justifyContent="space-between" row>
        <Text size="xs">Activity</Text>
        {selectedDate && <NavCalendar dates={dates} current={selectedDate} onChange={setSelectedDate} />}
      </Flex>
      <ResponsiveContainer width="99%" aspect={6 / 3} maxHeight={300}>
        <LineChart data={chartData} margin={{ left: 0 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="hour"
            tick={{ fill: '#868B8C', fontSize: 14 }}
            tickMargin={10}
            tickLine={false}
            axisLine={false}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis tick={{ fill: '#868B8C', fontSize: 14 }} domain={[0, 'dataMax + 5']} tickLine={false} axisLine={false}>
            <Label angle={270} position="insideLeft" offset={15} style={{ textAnchor: 'middle', fontSize: '80%' }}>
              Count of captures
            </Label>
          </YAxis>
          <Tooltip />
          <Line type="monotone" dataKey="count" stroke="#4DDFF3" strokeWidth={3} />
        </LineChart>
      </ResponsiveContainer>
    </StatsTab>
  );
};
