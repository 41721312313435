import { FC, ReactNode } from 'react';
import { Text } from '../text';
import { Flex } from '../flex';
import { LayoutItem } from '../layout-item';
import { FormFieldContent } from './form-field.styles';

interface FormFieldProps {
  title?: string;
  error?: { message?: string };
  children: ReactNode;
  right?: ReactNode;
  bottom?: ReactNode;
  bottomRight?: ReactNode;
}

export const FormField: FC<FormFieldProps> = ({ title, error, children, bottom, right, bottomRight }) => {
  return (
    <Flex gap={5}>
      <Text size="xs">{title}</Text>
      <Flex gap={5}>
        <FormFieldContent>
          {children}
          {right}
        </FormFieldContent>
        <Flex justifyContent="space-between" row>
          <Text size="xxs">{bottom}</Text>
          <Text size="xxs">{bottomRight}</Text>
        </Flex>
        <LayoutItem visible={!!error?.message}>
          <Text color="danger" size="xxs">
            {error?.message || '...'}
          </Text>
        </LayoutItem>
      </Flex>
    </Flex>
  );
};
