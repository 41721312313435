import dayjs from 'dayjs';

export const formatDate = (date: Date | string) => dayjs(date).format('dddd DD.MM.YYYY');
export const formatDatetime = (date: Date | string) => dayjs(date).format('DD.MM.YYYY HH:mm:ss');

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
export const formatBytes = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = sizes[i];
  const formattedBytes = i ? (bytes / 1024 ** i).toFixed(1) : bytes;
  return `${formattedBytes} ${size}`;
};
