import { FC } from 'react';
import { NavCalendarButton, NavCalendarCurrent, NavCalendarOption, NavCalendarWrapper } from './nav-calendar.styles';
import { Icon } from '@app/components/icon';

interface NavCalendarProps {
  dates: { day: number; month: number }[];
  current: { day: number; month: number };
  onChange: (date: { day: number; month: number }) => void;
}

function getMonthShortName(month: number) {
  const date = new Date();
  date.setMonth(month);

  return date.toLocaleString('en-US', { month: 'short' });
}

export const NavCalendar: FC<NavCalendarProps> = ({ dates, current, onChange }) => {
  const { month, day } = current;
  const currentIndex = dates.findIndex((d) => d.day === current.day && d.month === current.month);
  const prevDay = dates[currentIndex - 1] || null;
  const nextDay = dates[currentIndex + 1] || null;

  const handlePrev = () => {
    if (prevDay) onChange(prevDay);
  };

  const handleNext = () => {
    if (nextDay) onChange(nextDay);
  };

  return (
    <NavCalendarWrapper>
      <NavCalendarButton $rotate $active={!!prevDay} onClick={handlePrev}>
        <Icon name={'arrowRightSm'} color={prevDay ? 'primary' : 'grayDark'} size={14} />
      </NavCalendarButton>
      <NavCalendarOption>{prevDay?.day || '-'}</NavCalendarOption>
      <NavCalendarCurrent $day={String(day)} $month={getMonthShortName(month)}>
        <Icon name="calendar" size={37} />
      </NavCalendarCurrent>
      <NavCalendarOption>{nextDay?.day || '-'}</NavCalendarOption>
      <NavCalendarButton $active={!!nextDay} onClick={handleNext}>
        <Icon name={'arrowRightSm'} color={nextDay ? 'primary' : 'grayDark'} size={14} />
      </NavCalendarButton>
    </NavCalendarWrapper>
  );
};
