import { FC, useMemo, useState } from 'react';
import { ViewCapture } from '../../../views/captures/interfaces';
import { CapturePreview } from '@app/feautres/capture/components/capture-preview';
import { useElementSize } from '@app/hooks/use-element-size';
import { CaptureSlideContent, CaptureSlidePreview, CaptureSlideWrapper } from './capture-slide.styles';
import { CaptureActions } from '@app/feautres/capture/components/capture-actions';
import { LayoutItem } from '@app/layout/layout-item';
import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { exactlyMouseHandler } from '@app/utils/handlers.util';
import { CaptureIdProvider } from '@app/feautres/capture/providers/capture-id-provider';

interface CaptureSlideProps {
  capture: ViewCapture;
  onLoad: () => void;
  isCurrent: boolean;
  isZoomed: boolean;
  onSeek: () => void;
  shouldLoad: boolean;
  onBgClick: () => void;
}

export const CaptureSlide: FC<CaptureSlideProps> = ({ capture, shouldLoad, onLoad, isCurrent, isZoomed, onSeek, onBgClick }) => {
  const [ref, size] = useElementSize();
  const isDesktop = useBreakpoint('md');
  const [isLoaded, setIsLoaded] = useState(false);

  const maxCaptureSize = useMemo(() => {
    if (!size) return null;
    const shareSize = isDesktop ? 150 : 90;
    return { width: size.width, height: size.height - shareSize };
  }, [size, isDesktop]);

  return (
    <CaptureIdProvider captureId={capture.id}>
      <CaptureSlideWrapper onClick={exactlyMouseHandler(onBgClick)} ref={ref} className="swiper-zoom-container">
        {maxCaptureSize && shouldLoad && (
          <CaptureSlideContent>
            <CaptureSlidePreview className="swiper-zoom-target" onClick={exactlyMouseHandler(onBgClick)}>
              <CapturePreview
                maxWidth={maxCaptureSize.width}
                maxHeight={maxCaptureSize.height}
                capture={capture}
                spinnerColor="white"
                onLoad={() => {
                  setIsLoaded(true);
                  onLoad();
                }}
                videoAttrs={{
                  onSeeking: isCurrent ? onSeek : undefined,
                  controls: !isZoomed,
                  isPlaying: isCurrent,
                }}
              />
            </CaptureSlidePreview>
            <LayoutItem visible={isLoaded && !isZoomed}>
              <CaptureActions capture={capture} />
            </LayoutItem>
          </CaptureSlideContent>
        )}
      </CaptureSlideWrapper>
    </CaptureIdProvider>
  );
};
