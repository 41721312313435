import { FC } from 'react';
import { Icon } from '@app/components/icon';
import { Responsive } from '@app/components/responsive';
import { ViewsTabLabel, ViewsTabValue, ViewsTabValueContainer, ViewsTabWrapper } from './views-tab.styles';

interface ViewsTabProps {
  area: string;
  total: number;
  unique: number;
}

export const ViewsTab: FC<ViewsTabProps> = ({ area, total, unique }) => {
  return (
    <ViewsTabWrapper area={area}>
      <ViewsTabLabel>All views</ViewsTabLabel>
      <ViewsTabValueContainer>
        <Responsive target="mobile">
          <Icon name="eye" size={18} color="secondary" />
        </Responsive>
        <ViewsTabValue>{total}</ViewsTabValue>
      </ViewsTabValueContainer>
      <div style={{ background: 'black', height: '3px' }}></div>
      <ViewsTabValueContainer>
        <Responsive target="desktop">
          <Icon name="eye" size={36} color="secondary" />
        </Responsive>
        <ViewsTabValue $small>{unique}</ViewsTabValue>
        <ViewsTabLabel $small>Unique views</ViewsTabLabel>
      </ViewsTabValueContainer>
    </ViewsTabWrapper>
  );
};
