import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { usePortalContainer } from '@app/hooks/use-portal-container';
import arrowRightImg from '@app/assets/arrow-right.svg';
import { Responsive } from '@app/components/responsive';
import { exactlyMouseHandler } from '@app/utils/handlers.util';
import 'swiper/css';
import 'swiper/css/zoom';
import { CaptureModalNav, CaptureModalBg, CaptureModalClose, CaptureModalWrapper } from './capture-modal.styles';

interface CaptureModalProps {
  onClose: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  onNext: () => void;
  onPrev: () => void;
  children: ReactNode;
}

export const CaptureModal: FC<CaptureModalProps> = ({ onClose, hasNext, hasPrev, onNext, onPrev, children }) => {
  const el = usePortalContainer();

  return createPortal(
    <CaptureModalBg onMouseDown={exactlyMouseHandler(() => onClose())}>
      <CaptureModalWrapper onMouseDown={exactlyMouseHandler(() => onClose())}>{children}</CaptureModalWrapper>
      <Responsive target="desktop">
        <CaptureModalNav $disabled={!hasPrev} $direction="left" onClick={() => onPrev()}>
          <img src={arrowRightImg} alt="arrow left" />
        </CaptureModalNav>
        <CaptureModalNav $disabled={!hasNext} $direction="right" onClick={() => onNext()}>
          <img src={arrowRightImg} alt="arrow right" />
        </CaptureModalNav>
      </Responsive>
      <Responsive target="mobile">
        <CaptureModalClose name="closeBordered" size={20} onClick={() => onClose()} />
      </Responsive>
    </CaptureModalBg>,
    el,
  );
};
