import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { FC } from 'react';
import { Cell, Legend, Pie, PieChart, PieLabel } from 'recharts';

const RADIAN = Math.PI / 180;

const CustomLabel: PieLabel = ({ cx, cy, midAngle, outerRadius, value, fill }) => {
  const radius = 30 + outerRadius;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const xPos = x / cx;
  const textAnchor = xPos < 0.7 ? 'end' : xPos > 1.3 ? 'start' : 'middle';

  return (
    <text x={x} y={y} fill={fill} textAnchor={textAnchor} dominantBaseline="central">
      {value}
    </text>
  );
};

interface StatsPieChartsProps {
  data?: any[] | null;
}

export const StatsPieCharts: FC<StatsPieChartsProps> = ({ data }) => {
  const isLarge = useBreakpoint('sm');

  const chartData = data || [{ name: 'No data', value: 1, color: '#C3C3C3' }];

  return (
    <PieChart width={isLarge ? 300 : 250} height={270}>
      <Pie
        data={chartData}
        dataKey="value"
        nameKey="name"
        innerRadius={isLarge ? 45 : 35}
        outerRadius={isLarge ? 75 : 60}
        fill="#82ca9d"
        label={data ? CustomLabel : false}
      >
        {chartData.map((entry, index) => (
          <Cell key={index} fill={entry.color} />
        ))}
      </Pie>
      <Legend wrapperStyle={{ paddingTop: '10px' }} />
    </PieChart>
  );
};
