import { Outlet, useLocation } from 'react-router-dom';
import { Boundary } from '.';

export const RouterBoundary = () => {
  const { pathname } = useLocation();
  return (
    <Boundary key={pathname}>
      <Outlet />
    </Boundary>
  );
};
