import { FC, ReactNode } from 'react';
import { CapturesItemButtons, CapturesItemFilename, CapturesItemThumbnail, CapturesItemWrapper } from './captures-item.styles';
import notLoadedImg from '@app/assets/not-loaded.svg';
import { ViewCapture } from '../interfaces';

interface CapturesItemProps {
  buttons?: (capture: ViewCapture) => ReactNode;
  capture: ViewCapture;
  onClick: () => void;
}

export const CapturesItem: FC<CapturesItemProps> = ({ buttons, capture, onClick }) => {
  const thumbnailUrl = capture.thumbnail?.path || notLoadedImg;

  const { status, uploadFilename } = capture;

  const handleClick = () => {
    if (status !== 'PROCESSED') return;
    onClick();
  };

  return (
    <CapturesItemWrapper>
      <CapturesItemThumbnail src={thumbnailUrl} onClick={handleClick} />
      {buttons && <CapturesItemButtons className="buttons">{buttons(capture)}</CapturesItemButtons>}
      {status !== 'PROCESSED' && <CapturesItemFilename>{uploadFilename}</CapturesItemFilename>}
    </CapturesItemWrapper>
  );
};
