import { useCallback, useEffect, useState } from 'react';
import { SwiperClass } from 'swiper/react';

export const useSwiperLock = (swiperRef: SwiperClass | null) => {
  const [lastLock, setLastLock] = useState(0);

  useEffect(() => {
    if (!lastLock) return;
    swiperRef?.slideReset();
    swiperRef?.disable();
    const timeout = setTimeout(() => swiperRef?.enable(), 500);
    return () => clearTimeout(timeout);
  }, [lastLock, swiperRef]);

  const lock = useCallback(() => setLastLock(Date.now()), []);

  return lock;
};
