import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const InputWrapper = styled.div`
  position: relative;
`;

interface InputElementProps {
  $isError?: boolean;
  $withIcon?: boolean;
}
export const InputElement = styled.input<InputElementProps>`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.s};
  border: 0;
  border-width: 1px;
  border-bottom-color: ${({ theme, $isError }) => theme.colors[$isError ? 'danger' : 'gray']};
  border-bottom-style: solid;

  border-radius: 0;
  padding: 12px ${({ $withIcon }) => ($withIcon ? '20px' : '5px')};
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grayDark};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.grayLight};
  }

  &:focus {
    outline: none;
  }

  ${breakpointsMedia.md} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 10px;
  width: 12px;
  height: 12px;
`;
