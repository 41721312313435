import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';
import { Icon } from '@app/components/icon';

export const CaptureModalBg = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  box-sizing: border-box;
  z-index: 10;

  ${breakpointsMedia.md} {
    padding: 0 100px;
  }
`;

export const CaptureModalWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;

  ${breakpointsMedia.md} {
    padding-top: 0px;
  }
`;

export const CaptureModalNav = styled.div<{ $direction: 'left' | 'right'; $disabled: boolean }>`
  position: absolute;
  top: 50%;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  z-index: 10;
  opacity: ${({ $disabled }) => ($disabled ? '0.2' : '1')};
  user-select: none;

  ${({ $direction }) =>
    $direction === 'left'
      ? css`
          left: 20px;
          transform: translateY(-50%) rotate(180deg);
        `
      : css`
          right: 20px;
          transform: translateY(-50%) rotate(0deg);
        `}

  &::after {
    content: '';
    position: absolute;
    left: -30px;
    top: -30px;
    width: calc(100% + 60px);
    height: calc(100% + 60px);
  }
`;

export const CaptureModalClose = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
`;
