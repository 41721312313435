import { FC } from 'react';
import { useCaptureDownload } from '../../hooks/use-capture-download';
import { useCopyCaptureLink } from '../../hooks/use-copy-capture-link';
import { IconButton } from '@app/components/button';
import { ViewCapture } from '../../views/captures/interfaces';

interface BaseCaptureButtonsProps {
  capture: ViewCapture;
}

export const BaseCaptureButtons: FC<BaseCaptureButtonsProps> = ({ capture }) => {
  const startDownload = useCaptureDownload(capture.id);
  const copyCaptureLink = useCopyCaptureLink(capture);

  if (capture.status !== 'PROCESSED') return <></>;
  return (
    <>
      <IconButton icon="download" kind="whitePrimary" onClick={startDownload} />
      <IconButton icon="share" kind="whitePrimary" onClick={copyCaptureLink} />
    </>
  );
};
