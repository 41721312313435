import { FC, ReactNode, useEffect } from 'react';
import { ViewCapture } from '../../views/captures/interfaces';
import { useQueryParam } from '@app/hooks/use-query-param';
import { CaptureSwiper } from './capture-swiper';

interface CapturesViewerProps {
  captures: ViewCapture[];
  onSelectedChange?: (index: number) => void;
  children: (config: { handleChange: (capture: ViewCapture) => void }) => ReactNode;
}

export const CapturesViewer: FC<CapturesViewerProps> = ({ children, captures, onSelectedChange }) => {
  const [captureId, setCaptureId] = useQueryParam('captureId');
  const currentIndex = captures.findIndex((c) => c.id === captureId);

  useEffect(() => {
    if (currentIndex === -1) return;
    onSelectedChange?.(currentIndex);
  }, [currentIndex, onSelectedChange]);

  const handleChange = (capture: ViewCapture | null) => setCaptureId(capture && capture.id);
  const handleChangeIndex = (i: number | null) => setCaptureId(i !== null ? captures[i].id : null);

  return (
    <>
      {children({ handleChange })}
      {currentIndex >= 0 && <CaptureSwiper captures={captures} currentIndex={currentIndex} onChange={handleChangeIndex} />}
    </>
  );
};
