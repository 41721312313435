import { breakpointsMedia } from '@app/styles/breakpoints';
import { font } from '@app/styles/common';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const Textarea = styled(TextareaAutosize).withConfig({
  shouldForwardProp: (props) => !['isError'].includes(props),
})<{ isError?: boolean }>`
  ${font()}
  resize: none;
  border: 0;
  border-width: 1px;
  border-bottom-color: ${({ theme, isError }) => theme.colors[isError ? 'danger' : 'gray']};
  border-bottom-style: solid;

  border-radius: 0;
  padding: 12px 5px;
  box-sizing: border-box;

  font-size: ${({ theme }) => theme.fontSizes.s};
  ${breakpointsMedia.md} {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;
