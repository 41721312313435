import { groupBy } from 'lodash-es';
import { HourlyCount } from '../interfaces/hourly-count.interface';

const getArrayRange = (from: number, to: number) => Array.from(new Array(to - from + 1)).map((_, i) => i + from);

export const getChartData = (data: HourlyCount[] | null) => {
  if (!data) return [{ hour: 'No Data', count: '0' }];
  const selectedByHour = groupBy(data, (x) => x.hour);

  const selectedHours = data.map((d) => d.hour);
  const chartData = getArrayRange(Math.min(...selectedHours), Math.max(...selectedHours))
    .map((hour) => selectedByHour[hour]?.[0] || { hour, count: 0 })
    .map((x) => ({ ...x, hour: `${x.hour}:00` }));

  return chartData;
};
