import { FC, ReactNode } from 'react';
import { CapturesItem } from './captures-item/captures-item';
import { CapturesViewWrapper } from './captures-view.styles';
import { CaptureIdProvider } from '../../providers/capture-id-provider';
import { PageInfo } from '@app/layout/page-info';
import { ViewCapture } from './interfaces';
import { CapturesViewer } from '../../components/captures-viewer';

interface CapturesViewProps {
  captures: ViewCapture[];
  buttons?: (capture: ViewCapture) => ReactNode;
  thumbnailWidth?: number;
  onSelectedChange?: (index: number) => void;
}

export const CapturesView: FC<CapturesViewProps> = ({ captures, buttons, thumbnailWidth, onSelectedChange }) => {
  if (!captures.length) return <PageInfo>Not found any captures...</PageInfo>;
  return (
    <CapturesViewer captures={captures} onSelectedChange={onSelectedChange}>
      {({ handleChange }) => (
        <CapturesViewWrapper $thumbnailWidth={thumbnailWidth}>
          {captures.map((capture) => (
            <CaptureIdProvider key={capture.id} captureId={capture.id}>
              <CapturesItem key={capture.id} buttons={buttons} capture={capture} onClick={() => handleChange(capture)} />
            </CaptureIdProvider>
          ))}
        </CapturesViewWrapper>
      )}
    </CapturesViewer>
  );
};
