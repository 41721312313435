import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { FC, ReactNode } from 'react';

interface ResponsiveProps {
  children: ReactNode;
  target: 'mobile' | 'desktop';
}

export const Responsive: FC<ResponsiveProps> = ({ children, target }) => {
  const current = useBreakpoint('md') ? 'desktop' : 'mobile';
  return current === target ? children : <></>;
};
