import styled, { css } from 'styled-components';

export const StatsTab = styled.div.withConfig({
  shouldForwardProp: (props) => !['area', 'grid'].includes(props),
})<{ area?: string; grid?: boolean }>`
  box-shadow: 0px 0px 10px #0000000d;
  padding: 30px 20px;
  background: ${({ theme }) => theme.colors.white};

  ${({ area }) =>
    area &&
    css`
      grid-area: ${area};
    `};

  ${({ grid }) =>
    grid &&
    css`
      display: grid;
      gap: 25px;
    `};
`;
