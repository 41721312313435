import { FC } from 'react';
import { DefaultTheme } from 'styled-components';
import { Icon, IconName } from '@app/components/icon';
import { LayoutItem } from '@app/layout/layout-item';
import { Divider, PrimaryTabValue, PrimaryTabValueContainer, PrimaryTabWrapper } from './primary-tab.styles';
import { Text } from '@app/layout/text';

interface PrimaryTabProps {
  area: string;
  value: number;
  label: string;
  color: keyof DefaultTheme['colors'];
  emptyReason?: string;
  icon?: IconName;
}

export const PrimaryTab: FC<PrimaryTabProps> = ({ area, value, label, color, emptyReason, icon }) => {
  const realColor = emptyReason ? 'gray' : color;
  return (
    <PrimaryTabWrapper area={area} $emptyReason={emptyReason}>
      <LayoutItem color={realColor}>
        <PrimaryTabValueContainer>
          <Icon name={icon || 'human'} size={20} />
          <PrimaryTabValue>{value}</PrimaryTabValue>
        </PrimaryTabValueContainer>
      </LayoutItem>
      <Divider $color={realColor} />
      <Text size="xs" weight={300}>
        {label}
      </Text>
    </PrimaryTabWrapper>
  );
};
