import { FC } from 'react';
import { StatsViewBase, StatsViewCharts, StatsViewWarning, StatsViewWrapper } from './stats-view.styles';
import { PrimaryTab } from './primary-tab';
import { ActionsTab } from './actions-tab';
import { ViewsTab } from './views-tab';
import { StatsTab } from './stats-tab';
import { Text } from '@app/layout/text';
import { EmptyTopItems } from './empty-top-items';
import { CapturesView } from '@app/feautres/capture/views/captures';
import { ViewCapture } from '@app/feautres/capture/views/captures/interfaces';
import { DeviceViewsTab } from './device-views-tab';
import { HourlyCountsTab } from './hourly-counts-chart-tab';
import { DateCount } from './hourly-counts-chart-tab/interfaces/date-count.interface';
import { BaseCaptureButtons } from '@app/feautres/capture/components/base-capture-buttons/base-capture-buttons';
import { MarketingTab } from './marketing-tab';

interface StatsViewProps {
  isEarly: boolean;
  stats: {
    totalViews: number;
    uniqueViews: number;
    mobileViews: number;
    desktopViews: number;
    shareCount: number;
    downloadsCount: number;
    capturesCount: number;
    marketingCount: number;
    topCaptures: ViewCapture[];
    peopleCount?: number | null;
    printsCount: number;
    hourlyCounts: DateCount[];
  };
}

export const StatsView: FC<StatsViewProps> = ({ stats, isEarly }) => {
  const withMarketing = stats.marketingCount > 0;

  return (
    <StatsViewWrapper>
      {isEarly && <StatsViewWarning>Full stats will be available within 24 hours after event...</StatsViewWarning>}
      <StatsViewBase>
        <ViewsTab area="av" total={stats.totalViews} unique={stats.uniqueViews} />
        <PrimaryTab area="tc" color="turquoise" value={stats.capturesCount} label="Total captures" />
        <PrimaryTab
          emptyReason={Number.isInteger(stats.peopleCount) ? '' : 'unavailable...'}
          area="pc"
          color="primary"
          value={stats.peopleCount || 0}
          label="People on captures"
        />
        <PrimaryTab area="pu" color="black" value={stats.printsCount} label="Prints" icon="print" />
        <ActionsTab area="p" value={stats.downloadsCount} label="Downloads" icon="download" />
        <ActionsTab area="s" value={stats.shareCount} label="Shares" icon="share" />
      </StatsViewBase>
      {withMarketing && <HourlyCountsTab hourlyCounts={stats.hourlyCounts} />}
      <StatsViewCharts>
        <DeviceViewsTab {...stats} />
        {withMarketing ? <MarketingTab {...stats} /> : <HourlyCountsTab hourlyCounts={stats.hourlyCounts} />}
      </StatsViewCharts>
      <StatsTab grid>
        <Text size="xs">Most popular</Text>
        {stats.topCaptures.length ? (
          <CapturesView
            captures={stats.topCaptures}
            thumbnailWidth={365}
            buttons={(capture) => <BaseCaptureButtons capture={capture} />}
          />
        ) : (
          <EmptyTopItems />
        )}
      </StatsTab>
    </StatsViewWrapper>
  );
};
