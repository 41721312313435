import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';
import { StatsTab } from '../stats-tab';

export const ActionsTabWrapper = styled(StatsTab)`
  background: ${({ theme }) => theme.colors.primary};
  padding: 30px 15px;
  display: grid;
  gap: 0;
  grid-template-columns: max-content 1fr;
  justify-items: center;

  ${breakpointsMedia.md} {
    padding: 30px;
  }
`;

export const ActionsTabValue = styled.div`
  font-size: 28px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 10px;

  ${breakpointsMedia.md} {
    margin-top: 0;
    font-size: ${({ theme }) => theme.fontSizes['3xl']};
  }
`;

export const ActionsTabLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;

  ${breakpointsMedia.md} {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;
