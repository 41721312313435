import styled from 'styled-components';

export const EmptyTopItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

export const EmptyTopItemsItem = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  height: 200px;
`;
