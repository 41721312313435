import styled, { css, DefaultTheme } from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';
import { StatsTab } from '../stats-tab';

export const PrimaryTabWrapper = styled(StatsTab)<{ $emptyReason?: string }>`
  padding: 45px 20px;
  display: grid;
  gap: 15px;

  ${breakpointsMedia.md} {
    padding: 45px 35px;
    gap: 20px;
  }

  ${({ $emptyReason }) =>
    $emptyReason &&
    css`
      position: relative;
      opacity: 0.8;
      &::after {
        content: '${$emptyReason}';
        position: absolute;
        left: 20px;
        text-align: center;
        top: 15px;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.grayDark};

        ${breakpointsMedia.md} {
          left: 35px;
        }
      }
    `}
`;

export const PrimaryTabValueContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  ${breakpointsMedia.md} {
    gap: 20px;
  }
`;

export const PrimaryTabValue = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.base};
  font-weight: 800;

  ${breakpointsMedia.md} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

export const Divider = styled.div<{ $color: keyof DefaultTheme['colors'] }>`
  background: ${({ theme, $color }) => theme.colors[$color]};
  height: 3px;
`;
