import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';
import { StatsTab } from '../stats-tab/stats-tab';

export const ViewsTabWrapper = styled(StatsTab)`
  display: grid;
  gap: 15px;

  ${breakpointsMedia.lg} {
    padding: 65px;
    gap: 20px;
  }
`;

export const ViewsTabValueContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;

  ${breakpointsMedia.lg} {
    gap: 18px;
  }
`;

export const ViewsTabValue = styled.div<{ $small?: boolean }>`
  font-size: ${({ theme, $small }) => theme.fontSizes[$small ? 'xs' : 'xl']};
  font-weight: 800;

  ${breakpointsMedia.lg} {
    font-size: ${({ theme, $small }) => theme.fontSizes[$small ? 'xl' : '4xl']};
  }
`;

export const ViewsTabLabel = styled.div<{ $small?: boolean }>`
  font-size: ${({ theme, $small }) => theme.fontSizes[$small ? 'xxs' : 'xs']};
  font-weight: 300;

  ${breakpointsMedia.lg} {
    font-size: ${({ theme, $small }) => theme.fontSizes[$small ? 'base' : 'lg']};
  }
`;
